<template>
  <!--  style="width:1920px" -->
  <div id="app">

    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.$actions.onGlobalStateChange && this.$actions.onGlobalStateChange((state, prevState) => {
      // state: 变更后的状态; prevState: 变更前的状态
      console.log('微应用观察者：新值', state.token)
      console.log('微应用观察者：旧值 ', prevState.token)
      this.$store.commit('setUser', state.user)
    }, true)
  }
}
</script>

<style lang="stylus">

</style>
