import Vue from 'vue'
import VueRouter from 'vue-router'

import { wap, gbook } from './wap'

Vue.use(VueRouter)

const routes = [
  wap,
  gbook,
  {
    path: '/',
    name: 'Home',
    component: () => import('./../views/Home.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('./../views/Index.vue')
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },

  {
    path: '/baseHome',
    name: 'BaseHome',
    component: () => import('./../views/BaseHome.vue'),
    children: [
      // 关于我们下拉
      {
        path: 'joinus',
        name: 'Joinus',
        component: () => import('./../views/company/joinus.vue')
      },
      {
        path: 'cooperation',
        name: 'Cooperation',
        component: () => import('./../views/company/cooperation.vue')
      },
      {
        path: 'honor',
        name: 'Honor',
        component: () => import('./../views/company/honor.vue')
      },
      {
        path: 'culture',
        name: 'Culture',
        component: () => import('./../views/company/culture.vue')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('./../views/company/about.vue')
      },

      // 增值服务下拉
      {
        path: 'invoicing',
        name: 'Invoicing',
        component: () => import('./../views/service/invoicing.vue')
      },
      {
        path: 'valueAdded',
        name: 'ValueAdded',
        component: () => import('./../views/service/valueAdded.vue')
      },
      {
        path: 'ourservice',
        name: 'Ourservice',
        component: () => import('./../views/service/ourservice.vue')
      },
      // 产品详情下拉  networkTransport
      {
        path: 'platform',
        name: 'Platform',
        component: () => import('./../views/product/Platform.vue')
      },
      {
        path: 'appDownLoad',
        name: 'AppDownLoad',
        component: () => import('./../views/product/AppDownLoad.vue')
      },
      //
      //
      //
      //
      // 以下是以前的，现在没用，留着备用，最终官网确认之后再删除
      {
        path: 'brief',
        name: 'Brief',
        component: () => import('./../views/company/Brief.vue')
      },
      {
        path: 'notes',
        name: 'Notes',
        component: () => import('./../views/company/Notes.vue')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('./../views/company/Contact.vue')
      },
      // 加入我们
      {
        path: 'join',
        name: 'Join',
        component: () => import('./../views/company/Join.vue')
      },
      // 加入我们详情
      {
        path: 'joinDetail',
        name: 'JoinDetail',
        component: () => import('./../views/company/JoinDetail.vue')
      },
      // 主营业务
      {
        path: 'business',
        name: 'Business',
        component: () => import('./../views/company/Business.vue')
      },

      // 运营模式
      {
        path: 'operate',
        name: 'Operate',
        component: () => import('./../views/product/Operate.vue')
      },
      // 公司新闻
      {
        path: 'companyNews',
        name: 'CompanyNews',
        component: () => import('./../views/news/CompanyNews.vue')
      },
      // 行业新闻
      {
        path: 'industryNews',
        name: 'IndustryNews',
        component: () => import('./../views/news/IndustryNews.vue')
      },
      // 政策解读
      {
        path: 'policy',
        name: 'Policy',
        component: () => import('./../views/news/Policy.vue')
      },
      // 新闻详情
      {
        path: 'newscontent',
        name: 'NewsContent',
        component: () => import('./../views/news/NewsContent.vue')
      },

      // 保险服务
      {
        path: 'insurance',
        name: 'Insurance',
        component: () => import('./../views/service/Insurance.vue')
      },
      // 金融服务
      {
        path: 'finance',
        name: 'Finance',
        component: () => import('./../views/service/Finance.vue')
      },
      // ETC服务
      {
        path: 'etc',
        name: 'Etc',
        component: () => import('./../views/service/Etc.vue')
      },
      // 税筹
      {
        path: 'taxraising',
        name: 'TaxRaising',
        component: () => import('./../views/service/TaxRaising.vue')
      },
      // 汽车及后市场服务
      {
        path: 'car',
        name: 'Car',
        component: () => import('./../views/service/Car.vue')
      },
      // 网上商城-合作伙伴
      {
        path: 'Parters',
        name: 'Parters',
        component: () => import('./../views/service/Parters.vue')
      }

    ]
  },

  // 后台登录
  {
    path: '/backstage/login',
    name: 'login',
    component: () => import('@/vbackstage/login.vue')
  },
  {
    path: '/backstage',
    name: 'backStage',
    component: () => import('@/vbackstage/layout.vue'),
    redirect: '/backstage/home',
    children: [
      {
        path: '/backstage/home',
        name: 'home',
        component: () => import('@/vbackstage/backhome/backhome.vue')
      },
      {
        path: '/backstage/news',
        name: 'news',
        component: () => import('@/vbackstage/news/companyNews.vue')
      },
      {
        path: '/backstage/join',
        name: 'join',
        component: () => import('@/vbackstage/join/joinUs.vue')
      },
      {
        path: '/backstage/enterprise',
        name: 'enterprise',
        component: () => import('@/vbackstage/enterprise/enterpriseList.vue')
      }

    ]
  }

]

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  // base: window.__POWERED_BY_QIANKUN__ ? '/guanwang/' : process.env.BASE_URL,
  routes
  // 对于页面跳转，全部都返回到页面顶部。
  // scrollBehavior(to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // }
})

export default router
