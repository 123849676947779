
// import { ACCESS_TOKEN, USER_NAME, USER_INFO } from '@/store/mutation-types'

// import { login, logout } from '@/api/login'
// import { welcome } from '@/utils/util'
const user = {
  state: {
    token: '',
    username: '',
    realname: '',
    welcome: '',
    avatar: '',
    loginTime: '',
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_LOGIN_TIME: (state, times) => {
      state.loginTime = times
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username
      state.realname = realname
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  }
}

export default user
