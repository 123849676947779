import './config'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Storage from 'vue-ls'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.css'
import './assets/css/styles.styl'
import Http from '@/utils/request'
import BaiduMap from 'vue-baidu-map'

import 'jquery'
import $ from 'jquery'
import './assets/js/scrollSlide.js'

import './public-path'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'GTY9DGDFpqRGXFewtC5WCIF57c826R3w'
})

import './components/index.js'
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.use(Storage)
Vue.config.productionTip = false
Vue.prototype.$http = Http
// 高德地图：e1a81bc033828929588824f803086f14
// var map = new AMap.Map('container');
// eslint-disable-next-line no-undef

Vue.prototype.$uploadApi = (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'coing') ? 'https://img.shttwl.com/' : 'http://192.168.10.21:1995/'

// new Vue({
//   router,
//   store,
//   render: (h) => h(App)
// }).$mount('#app')

let instance = null
function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  const { container, onGlobalStateChange, setGlobalState, customProps } = props
  console.log('自定义的props参数', customProps)
  console.log(container)
  Vue.prototype.$actions = {
    onGlobalStateChange,
    setGlobalState
  }
  instance = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}
export async function mount(props) {
  console.log('[vue] props from main framework')
  render(props)
}
export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  // router = null;
}

