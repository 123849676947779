$(document).ready(function() {
    $(window).scroll(function() {
        $('.rightToLeft').each(function() {
        var pos = $(this).offset().top
        var winTop = $(window).scrollTop()
        if (pos < winTop + 600) {
            $(this).addClass('slide01')
        }
        })
    })
    $(window).scroll(function() {
        $('.leftToRight').each(function() {
        var pos = $(this).offset().top
        var winTop = $(window).scrollTop()
        if (pos < winTop + 600) {
            $(this).addClass('slide02')
        }
        })
    })
    $(window).scroll(function() {
        $('.downToUp').each(function() {
        var pos = $(this).offset().top
        var winTop = $(window).scrollTop()
        if (pos < winTop + 600) {
            $(this).addClass('slide03')
        }
        })
    })
    $(window).scroll(function() {
        $('.upToDown').each(function() {
        var pos = $(this).offset().top
        var winTop = $(window).scrollTop()
        if (pos < winTop + 600) {
            $(this).addClass('slide04')
        }
        })
    })
    $(window).scroll(function() {
        $('.scales').each(function() {
        var pos = $(this).offset().top
        var winTop = $(window).scrollTop()
        if (pos < winTop + 500) {
            $(this).addClass('slide05')
        }
        })
    })
})


