import Vue from 'vue'

Vue.component('common-editor', () => import('./commonWeditor'))

Vue.component('common-image-upload', () => import('./ImageUpload.vue')) // 上传文件
Vue.component('common-fiile-upload', () => import('./ImageUploadFile.vue')) // 上传文件

Vue.component('common-dialog', () => import('./commonDialog.vue')) // 上传文件

Vue.component('ueditor-wrap', () => import('./UeditorWrap.vue')) // 富文本编辑器

Vue.component('ueditor-upload-image', () => import('./ueditorUploadImage.vue')) // 富文本编辑器上传图片

Vue.component('add-or-updata', () => import('./vbackstage/news/addOrUpdata.vue'))

Vue.component('common-address', () => import('./commonAddres.vue'))
