
// 这是移动站的
const wap = {
  path: '/wap',
  name: 'wap',
  component: () => import('@/wap/layout.vue'),
  redirect: '/wap/home',
  children: [
    {
      path: '/wap/home',
      name: 'home',
      component: () => import('@/wap/wapHome.vue')
    }

  ]
}
// 这是公众号的
const gbook = {
  path: '/wap/gbook',
  name: 'gbook',
  component: () => import('@/wap/gbook/gbook.vue')
}

export { wap, gbook }
