import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: (process.env.NODE_ENV === 'production') ? '/' : '/api', // url = base url + request url

  // withCredentials: true, // send cookies when cross-domain requests
  transformRequest: [
    function(data) {
      if (data && data.ossUrl) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        ret = ret.substring(0, ret.lastIndexOf('&'))
        return ret
      } else {
        return JSON.stringify(data)
      }
    }
  ],
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['SYSTOKEN'] = ''
      if (config.data && config.data.ossUrl) {
        const obj = Object.assign({}, config.data)
        if (obj.ossUrl && obj.ossUrl.length) {
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded' //
        } else {
          config.headers['Content-Type'] = 'application/json' // application/x-www-form-urlencoded,
        }
      } else {
        config.headers['Content-Type'] = 'application/json' // application/x-www-form-urlencoded,
      }
    } else {
      config.headers['Content-Type'] = 'application/json' // application/x-www-form-urlencoded,
    }
    console.log(config)
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (router.history.current.fullPath.indexOf('backstage') > -1 && router.history.current.fullPath !== '/backstage/login') {
      if (new Date().getTime() - store.getters.loginTime > 60 * 60 * 8 * 1000) {
        if (router.history.current.fullPath !== '/backstage/login') {
          router.push({ name: 'login' })
          setTimeout(() => {
            window.location.reload()
            return
          }, 500)
        }
      } else {
        store.commit('SET_LOGIN_TIME', new Date().getTime())
      }

      if (store.getters.token !== '123456') {
        router.push({ name: 'login' })
        setTimeout(() => {
          window.location.reload()
          return
        }, 500)
      }
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 1) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3000
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service
